import "../styles/NextPageLoader.css";

function NextPageLoader() {
  return (
    <div id="next-page-spinner">
      <div className="rect1"></div>
      <div className="rect2"></div>
      <div className="rect3"></div>
      <div className="rect4"></div>
      <div className="rect5"></div>
    </div>
  );
}

export default NextPageLoader;
