// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";

// Your web app's Firebase configuration
const firebaseConfig = {
  apiKey: "AIzaSyDR-4XIVFn4CVL5037oA6-PsLHRgXwqgmM",
  authDomain: "clone-theodinproject.firebaseapp.com",
  projectId: "youtube-clone-theodinproject",
  storageBucket: "youtube-clone-theodinproject.appspot.com",
  messagingSenderId: "695532645325",
  appId: "1:695532645325:web:15a0ccd0981d9ab7fdf284",
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);

export default app;
